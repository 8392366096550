<template>
  <div class="auth__area trial__page__on_premise_trial">
    <Loader full :loading="isLoading" />

    <h1 v-if="product === 'server'">{{ $t('FileCloud Server Trial') }}</h1>
    <h1 v-else-if="product === 'community'">
      {{ $t('FileCloud Community Edition') }}
    </h1>
    <h4
      v-html="
        $t('Install and run FileCloud on your own Windows and Linux servers')
      "
    />

    <StepsWrapper v-if="product === 'server'" ref="steps">
      <Step :title="$t('Basic Information')">
        <form
          v-if="!isPending"
          class="trial__page__online_trial"
          @submit.prevent="validateFormStep('basicInformation')"
        >
          <div v-if="errors._message" class="alert alert-danger">
            {{ errors._message }}
          </div>

          <div class="form-row">
            <InputBox
              id="name"
              v-model="form.name"
              :label="$t('Full Name')"
              :focus="true"
              type="text"
              class="col-md-6"
              :errors="errors"
              @input="removeError('name')"
            />
            <InputBox
              id="email"
              v-model="form.email"
              :label="$t('Email Address')"
              type="text"
              class="col-md-6"
              :errors="errors"
              @input="removeError('email')"
            />
          </div>
          <div v-if="product === 'community'" class="form-row">
            <SelectBox
              id="country"
              v-model="form.country"
              class="col-md-6"
              :label="$t('Country')"
              :placeholder="$t('Select...')"
              :errors="errors"
              @input="removeError('country')"
            >
              <option
                v-for="country in countries"
                :key="country"
                :value="country"
              >
                {{ country }}
              </option>
            </SelectBox>
            <InputBox
              id="company"
              v-model="form.company"
              :label="$t('Company')"
              type="text"
              class="col-md-6"
              :errors="errors"
              optional
            />
          </div>

          <div v-else class="form-row">
            <InputBox
              id="company"
              v-model="form.company"
              :label="$t('Company')"
              type="text"
              class="col-md-6"
              :errors="errors"
              @input="removeError('company')"
            />

            <PhoneInputBox
              id="phone"
              ref="phone"
              v-model="form.phone"
              :label="$t('Phone Number')"
              class="col-md-6"
              :errors="errors"
              @input="removeError('phone')"
            />
          </div>

          <div v-if="product === 'community'" class="form-row">
            <SelectBox
              id="usagetype"
              v-model="form.usagetype"
              :label="$t('How will you be using FileCloud Community Edition?')"
              :placeholder="$t('Select...')"
              :errors="errors"
              @input="removeError('usagetype')"
            >
              <option value="personal use">{{ $t('Personal Use') }}</option>
              <option value="business use">{{ $t('Business Use') }}</option>
            </SelectBox>
            <SelectBox
              id="licensecount"
              v-model="form.licensecount"
              :label="$t('How many employees?')"
              :placeholder="$t('Select...')"
              :errors="errors"
              style="align-self: flex-end"
              @input="removeError('licensecount')"
            >
              <option v-for="item in licenseamounts" :key="item" :value="item">
                {{ item }}
              </option>
            </SelectBox>
          </div>

          <div class="form-row d-flex flex-column my-3">
            <div
              class="form-group form-checkbox pt-1 mb-0 pl-1 flex-wrap"
              :class="{ ['is-invalid']: errors['tos'] }"
            >
              <Checkbox
                id="tos"
                v-model="form.tos"
                :errors="errors"
                :aria-label="$t('I have read and agree to the terms of use')"
                @input="removeError('tos')"
              />
              <label for="tos"
                >I have read and agree to the
                <a href="https://www.filecloud.com/eula/" target="_blank"
                  >EULA</a
                >
                and 
                <a href="https://www.filecloud.com/product-privacy-notice/" target="_blank"
                  >Product Privacy Notice</a
                ></label
              >
              <small v-if="errors['tos']">{{
                $t('You must read and agree in order to proceed')
              }}</small>
            </div>
          </div>

          <div
            class="
              row
              no-gutters
              flex-row-reverse
              d-sm-flex
              justify-content-between
              align-items-center
              mx-5
              mb-3
              pt-3
              mx-lg-0
            "
          >
            <div
              class="d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0"
            >
              <button
                v-if="product !== 'community'"
                class="btn btn-link mr-4"
                type="button"
                @click="$emit('changeStep', { name: 'welcome' })"
              >
                {{ $t('Back') }}
              </button>

              <button
                v-else
                class="btn btn-link mr-4"
                type="button"
                @click="onCloseTrial"
              >
                {{ $t('Back') }}
              </button>

              <button type="submit" class="btn btn-primary col">
                {{ $t('Next') }}
              </button>
            </div>

            <div class="col-sm-12 col-lg-4">
              <SupportBlock minified :community="product === 'community'" />
            </div>
          </div>
        </form>

        <form v-else class="trial__page__online_trial">
          <h4 class="text-pending">
            {{ pendingMessage }}
          </h4>

          <div
            class="
              row
              no-gutters
              flex-row-reverse
              d-sm-flex
              justify-content-between
              align-items-center
              mx-5
              mb-3
              pt-3
              mx-lg-0
            "
          >
            <div
              class="d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0"
            >
              <button
                v-if="product !== 'community'"
                class="btn btn-link ml-auto"
                type="button"
                @click="$emit('changeStep', { name: 'welcome' })"
              >
                {{ $t('Back') }}
              </button>

              <button
                v-else
                class="btn btn-link ml-auto"
                type="button"
                @click="onCloseTrial"
              >
                {{ $t('Back') }}
              </button>
            </div>

            <div class="col-sm-12 col-lg-4">
              <SupportBlock minified />
            </div>
          </div>
        </form>
      </Step>

      <Step :title="$t('General Information')">
        <form
          class="intro-body has-transition"
          @submit.prevent="validateFormStep('generalInformation', onSubmit)"
        >
          <div v-if="errors._message" class="alert alert-danger">
            {{ errors._message }}
          </div>
          <div class="form-row">
            <MultiColumnSelect
            id="industry"
            v-model="form.industry"
            class="col-md-6"
            :label="$t('Industry?')"
            placeholder="Please Select"
            :errors="errors"
            :column1="[
             { value: 'Apparel', text: 'Apparel' },
             { value: 'Architecture', text: 'Architecture' },
             { value: 'Automotive', text: 'Automotive' },
             { value: 'Aviation / Aerospace', text: 'Aviation / Aerospace' },
             { value: 'Chemicals', text: 'Chemicals' },
             { value: 'Construction', text: 'Construction' },
             { value: 'Defense / Space', text: 'Defense / Space' },
             { value: 'Education', text: 'Education' },
             { value: 'Energy/Oil & Gas', text: 'Energy/Oil & Gas' },
             { value: 'Engineering', text: 'Engineering' },
             ]"
             :column2="[
            { value: 'Entertainment', text: 'Entertainment' },
            { value: 'Financial / Banking', text: 'Financial / Banking' },
            { value: 'Food & Beverage', text: 'Food & Beverage' },
            { value: 'Government - Federal/City/State', text: 'Government - Federal/City/State', },
            { value: 'Healthcare/Life Sciences', text: 'Healthcare/Life Sciences', },
            { value: 'Information Technology/Software Services',  text: 'Information Technology/Software Services', },
            { value: 'Insurance', text: 'Insurance' },
            { value: 'Legal', text: 'Legal' },
            ]"
            :column3="[
            { value: 'Machinery', text: 'Machinery' },
            { value: 'Manufacturing/Heavy Industry', text: 'Manufacturing/Heavy Industry', },
            { value: 'Media/Publishing', text: 'Media/Publishing' },
            { value: 'Not For Profit', text: 'Not For Profit' },
            { value: 'Retail/Wholesale/E-commerce', text: 'Retail/Wholesale/E-commerce', },
            { value: 'Telecommunications', text: 'Telecommunications' },
            { value: 'Utilities', text: 'Utilities' },
            { value: 'Other', text: 'Other' }, //updated
            ]"
            @input="removeError('industry')"
            />
            <MultiSelect
              id="objectives"
              v-model="form.objectives"
              :label="$t('What are your objectives with FileCloud?')"
              class="col-md-6"
              placeholder="Please Select"
              :errors="errors"
              :options="[
                { value: 'Secure File Sharing', text: 'Secure File Sharing' },
                { value: 'Compliance', text: 'Compliance' },
                {
                  value: 'Central Data Repository',
                  text: 'Central Data Repository',
                },
                {
                  value: 'Infrastructure Modernization',
                  text: 'Infrastructure Modernization',
                },
                {
                  value: 'Remote Access without VPN',
                  text: 'Remote Access without VPN',
                },
                { value: 'Other', text: 'Other' },
              ]"
              @input="removeError('objectives')"
            />
          </div>

          <div class="form-row">
            <ReferralSelectBox
              id="referral"
              v-model="form.referral"
              :label="$t('How did you hear about us?')"
              class="col-md-6"
              placeholder="Please Select"
              :errors="errors"
              :options="[
                {
                  value: 'Referral / External File Share',
                  text: 'Referral / External File Share',
                },
                { value: 'Existing Customer', text: 'Existing Customer' },
                { value: 'Web Search', text: 'Web Search' },
                { value: 'Other', text: 'Other' },
              ]"
              @input="removeError('referral')"
            />
            <SelectBox
              id="time_frame"
              v-model="form.time_frame"
              :label="$t('Purchase Time Frame?')"
              class="col-md-6"
              placeholder="Please Select"
              :errors="errors"
              @input="removeError('time_frame')"
            >
              <option
                v-for="item in purchasetimeframe"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </SelectBox>
          </div>

          <div class="form-row">
            <SelectBox
              id="planned_users"
              v-model="form.planned_users"
              :label="$t('Number of planned users?')"
              class="col-md-6"
              placeholder="Please Select"
              :errors="errors"
              @input="removeError('planned_users')"
            >
              <option v-for="item in licenseamounts" :key="item" :value="item">
                {{ item }}
              </option>
            </SelectBox>
          </div>

          <div class="form-row">
            <div class="break"></div>
            <!--hr -->
          </div>
          <div
            class="
              row
              no-gutters
              flex-row-reverse
              d-sm-flex
              justify-content-between
              align-items-center
              mx-5
              mb-3
              pt-3
              mx-lg-0
            "
          >
            <div
              class="d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0"
            >
              <button
                class="btn btn-link mr-4"
                type="button"
                @click="$refs.steps.previousStep()"
              >
                {{ $t('Back') }}
              </button>
              <button type="submit" class="btn btn-primary col">
                {{ $t('Next') }}
              </button>
            </div>

            <div class="col-sm-12 col-lg-4">
              <SupportBlock minified />
            </div>
          </div>
        </form>
      </Step>
    </StepsWrapper>

    <div v-else-if="product === 'community'">
      <form
        v-if="!isPending"
        class="trial__page__online_trial"
        @submit.prevent="onSubmit"
      >
        <div v-if="errors._message" class="alert alert-danger">
          {{ errors._message }}
        </div>

        <div class="form-row">
          <InputBox
            id="name"
            v-model="form.name"
            :label="$t('Full Name')"
            :focus="true"
            type="text"
            class="col-md-6"
            :errors="errors"
            @input="removeError('name')"
          />

          <InputBox
            id="email"
            v-model="form.email"
            :label="$t('Email Address')"
            type="text"
            class="col-md-6"
            :errors="errors"
            @input="removeError('email')"
          />
        </div>
        <div class="form-row">
          <SelectBox
            id="country"
            v-model="form.country"
            class="col-md-6"
            :label="$t('Country')"
            :placeholder="$t('Select...')"
            :errors="errors"
            @input="removeError('country')"
          >
            <option
              v-for="country in countries"
              :key="country"
              :value="country"
            >
              {{ country }}
            </option>
          </SelectBox>

          <InputBox
            id="company"
            v-model="form.company"
            :label="$t('Company')"
            type="text"
            class="col-md-6"
            :errors="errors"
            optional
            @input="removeError('company')"
          />
        </div>

        <div class="form-row">
          <SelectBox
            id="usagetype"
            v-model="form.usagetype"
            :label="$t('How will you be using FileCloud Community Edition?')"
            :placeholder="$t('Select...')"
            :errors="errors"
            @input="removeError('usagetype')"
          >
            <option value="personal use">{{ $t('Personal Use') }}</option>
            <option value="business use">{{ $t('Business Use') }}</option>
          </SelectBox>
          <SelectBox
            id="licensecount"
            v-model="form.licensecount"
            :label="$t('How many employees?')"
            :placeholder="$t('Select...')"
            :errors="errors"
            style="align-self: flex-end"
            @input="removeError('licensecount')"
          >
            <option v-for="item in licenseamounts" :key="item" :value="item">
              {{ item }}
            </option>
          </SelectBox>
        </div>

        <div class="form-row d-flex flex-column my-3">
          <div
            class="form-group form-checkbox pt-1 mb-0 pl-1 flex-wrap"
            :class="{ ['is-invalid']: errors['tos'] }"
          >
            <Checkbox
              id="tos"
              v-model="form.tos"
              :errors="errors"
              :aria-label="$t('I have read and agree to the terms of use')"
              @input="removeError('tos')"
            />
            <label for="tos"
              >I have read and agree to the
              <a href="https://www.filecloud.com/eula/" target="_blank"
                >EULA</a
              >
              and 
              <a href="https://www.filecloud.com/product-privacy-notice/" target="_blank"
                >Product Privacy Notice</a
              ></label
            >
            <small v-if="errors['tos']">{{
              $t('You must read and agree in order to proceed')
            }}</small>
          </div>
        </div>

        <div
          class="
            row
            no-gutters
            flex-row-reverse
            d-sm-flex
            justify-content-between
            align-items-center
            mx-5
            mb-3
            pt-3
            mx-lg-0
          "
        >
          <div class="d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0">
            <button
              class="btn btn-link mr-4"
              type="button"
              @click="onCloseTrial"
            >
              {{ $t('Back') }}
            </button>

            <button type="submit" class="btn btn-primary col">
              {{ $t('Next') }}
            </button>
          </div>

          <div class="col-sm-12 col-lg-4">
            <SupportBlock minified :community="product === 'community'" />
          </div>
        </div>
      </form>

      <form v-else class="trial__page__online_trial">
        <h4 class="text-pending">
          {{ pendingMessage }}
        </h4>

        <div
          class="
            row
            no-gutters
            flex-row-reverse
            d-sm-flex
            justify-content-between
            align-items-center
            mx-5
            mb-3
            pt-3
            mx-lg-0
          "
        >
          <div class="d-flex align-items-center col col-lg-6 mb-3 mb-3 mb-lg-0">
            <button
              class="btn btn-link ml-auto"
              type="button"
              @click="onCloseTrial"
            >
              {{ $t('Back') }}
            </button>
          </div>

          <div class="col-sm-12 col-lg-4">
            <SupportBlock minified />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputBox from '@/components/InputBox';
import SelectBox from '@/components/SelectBox';
import MultiColumnSelect from '@/components/MultiColumnSelect';
import MultiSelect from '@/components/MultiSelect';
import ReferralSelectBox from '@/components/ReferralSelectBox';
import Loader from 'common/components/Loader';
import Checkbox from 'common/components/Checkbox';
import PhoneInputBox from '@/components/PhoneInputBox';
import SupportBlock from '@/components/Partials/SupportBlock';
import StepsWrapper from '@/components/TrialSignup/TrialFormSteps/StepsWrapper';
import Step from '@/components/TrialSignup/TrialFormSteps/Step';

import purchasetimeframe from '@/constants/purchasetimeframe';
import CountriesList from '@/constants/salesforce-countries';
import licenseamounts from '@/constants/licenseamounts';

export default {
  name: 'OnPremiseTrial',
  components: {
    InputBox,
    SelectBox,
    PhoneInputBox,
    Loader,
    Checkbox,
    SupportBlock,
    StepsWrapper,
    Step,
    MultiColumnSelect,
    MultiSelect,
    ReferralSelectBox,
  },
  props: {
    product: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        email: '',
        name: '',
        country: '',
        company: '',
        phone: '',
        objectives: '',
        industry: '',
        referral: '',
        time_frame: '',
        planned_users: '',
        recaptcha_response: '',
      },
      isBusinessUse: false,
      countries: CountriesList,
      isPending: false,
      pendingMessage: null,
      errors: {},
      licenseamounts,
      purchasetimeframe,
      schemas: {
        basicInformation: {
          name: {
            type: 'string',
            empty: false,
          },
          email: {
            type: 'email',
            empty: false,
          },
          phone: {
            type: 'string',
            empty: false,
            rules: [
              {
                name: 'validUSPhone',
                validator: () => {
                  if (this.$refs.phone.activeCountry.iso2.toLowerCase() === 'us' && !this.$refs.phone.isValidUSNumber){
                    return { isValid: false, error: 'Invalid phone number. A valid US number has 10 digits.' }
                  } else {
                    return { isValid: true }
                  }
                }
              }
            ]
          },
          company: {
            type: 'string',
            empty: false,
          },
          tos: {
            type: 'equal',
            value: true,
            strict: true,
            messages: {
              equalValue: 'You must read and agree in order to proceed',
            },
          },
        },
        generalInformation: {
          company: {
            type: 'string',
            empty: false,
          },
          industry: {
            type: 'string',
            empty: false,
          },
          objectives: {
            type: 'string',
            empty: false,
          },
          referral: {
            type: 'string',
            empty: false,
          },
          time_frame: {
            type: 'string',
            empty: false,
          },
          planned_users: {
            type: 'string',
            empty: false,
          },
        },
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.core['user'];
    },
    isLoading() {
      return this.$store.state.loading['core/trialSignup'];
    },
  },
  mounted() {
    this.setUserGeo();
    if (this.user.email) {
      this.form = this.user;
    }
  },
  methods: {
    removeError(field) {
      if (this.form[field]) {
        delete this.errors[field];
      }
    },
    onCloseTrial() {
      if (window.parent) {
        // send message for closing modal
        window.parent.postMessage(
          JSON.stringify({ action: 'trial-close' }),
          '*'
        );
      } else {
        window.location.replace('https://ce.filecloud.com/');
      }
    },
    onSelect(page) {
      this.$emit('changePage', page);
    },
    async setUserGeo() {
      if (this.$store.state.core.isGeoLocationScriptLoaded) {
        return;
      }

      await this.$store.dispatch('core/getUserGeo');
      const response = await this.$store.state.core.userGeo;
      let domain = '';
      if (response.country == 'China') {
        domain = 'www.recaptcha.net';
      } else {
        domain = 'www.google.com';
      }
      let recaptchaScript = document.createElement('script')
      let scriptSrc = `https://${domain}/recaptcha/api.js?render=6Lds_HMpAAAAAG_s_ViHbX191g9hvQxXbhvtOTxV`;
      recaptchaScript.setAttribute('src', scriptSrc);
      document.head.appendChild(recaptchaScript);
      this.$store.state.core.isGeoLocationScriptLoaded = true;
    },
    getRecaptchaToken() {
      return new Promise((resolve) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute('6Lds_HMpAAAAAG_s_ViHbX191g9hvQxXbhvtOTxV', { action: 'submit' })
              .then((token) => { resolve(token); });
        });
      })
    },
    async onSubmit() {
      this.form.recaptcha_response = await this.getRecaptchaToken();
      // if no country code included
      if (
        this.form.phone &&
        this.form.phone[0] !== '+' &&
        this.$refs.phone?.activeCountry.dialCode
      ) {
        this.form.phone = `+${this.$refs.phone.activeCountry.dialCode} ${this.form.phone}`;
      }

      const country = this.$refs.phone?.activeCountry || this.form.country;

      const response = await this.$store.dispatch('core/trialSignup', {
        ...this.form,
        country: CountriesList[country.iso2]
          ? CountriesList[country.iso2]
          : country?.name || country,
        producttype: this.product === 'server' ? 0 : 2,
        iscommunityedition: this.product === 'community' ? 1 : 0,
        licensecount: this.form.licensecount,
      });

      if (!response.ok) {
        this.errors =
          typeof response.error === 'string'
            ? { _message: response.error }
            : response.error;
        return;
      }

      this.errors = {};

      if (response.data.pending) {
        this.pendingMessage = response.data.message;
        this.isPending = true;
      } else {
        this.$emit('changeStep', { name: 'confirm', product: this.product });

        this.$store.dispatch('core/sendAnalyticsEvent', {
          category: 'cerebro-trial',
          action: `signup-${
            this.product === 'server' ? 'onpremise' : this.product
          }`,
          label: this.form.email,
        });
      }
    },

    validateFormStep(formTitle, callback) {
      this.errors = {};
      callback = callback || this.$refs.steps.nextStep;
      const schema = this.schemas[formTitle];
      if (!schema) return callback();

      const isValid = this.$refs.steps.validator.validate(this.form, schema);

      if(formTitle === 'basicInformation' && this.$refs.phone.activeCountry.iso2.toLowerCase() === 'us' && !this.$refs.phone.isValidUSNumber){
    this.errors.phone = { message: 'Invalid phone number. A valid US number has 10 digits.' };
    return
  }

      if (isValid === true) return callback();
      this.errors = this.$refs.steps.mapErrors(isValid);
    },
  },
};
</script>
