<template>
  <div v-show="showStep">
    <slot :title="title"></slot>
  </div>
</template>

<script>
export default {
  name: 'Step',
  inject: ['activeStepTitle'],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    showStep() {
      return this.title === this.activeStepTitle();
    },
  },
};
</script>

<style lang="scss" scoped></style>
