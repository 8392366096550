<template>
  <div class="trial__banner">
    <div class="trial__banner__background">
      <div class="trial__banner_text">
        <h1>{{ $t('Community Edition includes:') }}</h1>
        <ul>
          <li>
            {{ $t('1 year license') }}
          </li>
          <li>
            {{ $t('5 full user accounts') }}
          </li>
          <li>
            {{ $t('Mobile and desktop apps – Sync and Drive') }}
          </li>
          <li>
            {{ $t('Community Support') }}
          </li>
        </ul>
      </div>
      <div class="trial__banner__effect"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
