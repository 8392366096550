<template>
  <div class="trial__banner">
    <div class="trial__banner__background">
      <div class="trial__banner_text">
        <h1>{{ $t('Your free trial includes:') }}</h1>
        <ul>
          <li>
            {{ $t('30 days free (up to 20 users)') }}
          </li>
          <li>
            {{ $t('All features enabled') }}
          </li>
          <li>
            {{ $t('Run FileCloud on your on-premises servers or in the cloud (AWS, Azure, etc.)') }}
          </li>
          <li>
            {{ $t('Mobile and Desktop Apps - Sync, Drive, Add-ons for Office & Outlook') }}
          </li>
          <li>
            {{ $t('Full support during trial') }}
          </li>
        </ul>
      </div>
      <div class="trial__banner__effect"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
