<template>
  <LoginPageWrapper :step='step' class="trial__wrapper">
    <template #welcome>
      <Welcome
        :no-history="noHistory"
        @changeStep="onChangeStep"
      />
    </template>

    <template #online>
      <FormOnline
        :product="product"
        @changeStep="onChangeStep"
      />
    </template>

    <template #server>
      <FormServer
        :product="product"
        @changeStep="onChangeStep"
      />
    </template>
    
    <template #community>
      <FormServer
        :product="product"
        @changeStep="onChangeStep"
      />
    </template>

    <template #confirm>
      <FormVerificationCode
        :no-history="noHistory"
        :product="product"
        @changeStep="onChangeStep"
      />
    </template>

    <template #banner>
      <div v-if="noHistory" class="btn btn-icon btn-close" @click="onCloseTrial">
        <Icon name="times" />
      </div>
      <BannerOnline v-if="product === 'online'" />
      <BannerServer v-else-if="product === 'server'" />
      <BannerCommunity v-else-if="product === 'community'" />
      <img
        v-else
        class='login__main-bg main-bg'
        src='../assets/aurora-bg.jpg'
      />
    </template>

  </LoginPageWrapper>
</template>

<script>
import store from 'store';
import LoginPageWrapper from '@/components/Auth/LoginPageWrapper';
import Icon from 'common/components/Icon';

import Welcome from '@/components/TrialSignup/Welcome';
import FormOnline from '@/components/TrialSignup/FormOnline';
import FormServer from '@/components/TrialSignup/FormServer';
import FormVerificationCode from '@/components/TrialSignup/VerificationCode';

// banners
import BannerOnline from '@/components/TrialSignup/Banners/Online';
import BannerServer from '@/components/TrialSignup/Banners/Server';
import BannerCommunity from '@/components/TrialSignup/Banners/Community';

export default {
  name: 'Trial',
  components: {
    Icon,
    Welcome,
    FormOnline,
    FormServer,
    FormVerificationCode,
    LoginPageWrapper,
    BannerOnline,
    BannerServer,
    BannerCommunity
  },
  props: {
    noHistory: Boolean
  },
  data() {
    return {
      /**
       * @step 'login' | 'password | 'code'
       */
      // step: 'code',
      timeout: 0,
      // used for non router only
      data: {
        section: null,
        product: null,
      },
    };
  },
  computed: {
    step() {
            
      const product = (this.noHistory) ? this.data.product : this.$route.params.product;
      const section = (this.noHistory) ? this.data.section : this.$route.params.section;
      
      if(section) {
        return section;
      }

      if(['online', 'server', 'community'].indexOf(product) > -1) {
        return product;
      }

      return 'welcome';
    },
    product() {
      return (this.noHistory) ? this.data.product : this.$route.params.product;
    }
  },
  mounted() {
    if(this.noHistory && window.location.hash) {
      const urlParams = new URLSearchParams(window.location.hash.split('?')[1])
      if(urlParams.has('type')) {
        this.data.product = urlParams.get('type'); 
        this.data.section = urlParams.get('type'); 
      }
      if(urlParams.has('utm')) {
        try {
          const utm_params = JSON.parse(atob(urlParams.get('utm')));
          
          utm_params.initial_landing_page = decodeURIComponent(utm_params.initial_landing_page);
          utm_params.last_landing_page = decodeURIComponent(utm_params.last_landing_page);

          try {
            utm_params.gclid = new window.URL(utm_params.last_landing_page).searchParams.get('gclid') || new window.URL(utm_params.initial_landing_page).searchParams.get('gclid') || undefined;
          } catch (e) {
            // silence is golden
          }

          store.set('utm_params', utm_params);

        } catch (e) {
          console.log(e);
          // silence is golden
        }
      }
    }
  },
  methods: {
    onCloseTrial() {
      if(window.parent) {
        // send message for closing modal
        window.parent.postMessage(JSON.stringify({ action: 'trial-close' }), '*');
      }
    },
    onChangeProp(key, value) {
      this[key] = value;
    },
    onChangeStep({ name, product, timeout }) {
            
      if(timeout) {
        this.timeout = timeout;
      }
      
      if(this.noHistory) {
        
        if(name === 'welcome') {
          this.data = {
            section: 'welcome',
            product: null
          }
        } else {
          this.data = {
            section: name,
            product: (product) ? product : name
          }
        }

        return;
      }
      
      if(name === 'welcome') {
        this.$router.push('/trial')
      } else {
        this.$router.push(`/trial${(product) ? `/${product}` : ''}/${name}`)
      }
    }
  }
};
</script>

<style lang="scss" scoped>

@import '../scss/base-trial.scss';

</style>
