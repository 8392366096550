<template>
  <div
    class="ReferralSelectBox form-group"
    :class="{ [`is-invalid`]: errors[id] }"
  >
    <label :for="id" class="">{{ label }}</label>

    <div
      ref="selectWrapper"
      class="chosen-value input-group"
      :class="{ placeholder: !chosenValueLocal.length }"
      @click="toggleDropdown"
    >
      {{ chosenValueLocal || placeholder }}
      <Icon class="form-select-icon" family="fas" name="chevron-down" />
    </div>

    <div v-show="dropdownVisible" ref="dropdown" class="dropdown">
      <div
        v-for="(option, index) in options"
        :key="index"
        @click.stop="selectValue(option.value)"
      >
        <span v-text="option.text"></span>
      </div>
    </div>

    <small
      v-if="errors[id]"
      class="form-text text-danger"
      v-html="errors[id].message"
    ></small>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  name: 'ReferralSelectBox',
  components: { Icon },
  props: {
    id: String,
    label: String,
    value: String,
    placeholder: String,
    options: Array,
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dropdownVisible: false,
      chosenValue: '',
      chosenValueLocal: '',
    };
  },
  watch: {
    value(value) {
      this.chosenValue = this.getOptionText(value);
      this.chosenValueLocal = this.chosenValue;
    },
    chosenValue(value) {
      this.chosenValueLocal = value;
    },
  },
  mounted() {
    document.addEventListener('mouseup', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    selectValue(value) {
      if (value === 'other') {
        // default value for 'Other' option, can be any non-empty string change
        this.chosenValue = 'Other';
      } else {
        this.chosenValue = value;
      }
      this.$emit('input', this.chosenValue);
      this.dropdownVisible = false;
    },
    getOptionText(value) {
      const option = this.options.find((option) => option.value === value);
      return option ? option.text : '';
    },
    handleClickOutside(event) {
      const selectWrapper = this.$refs.selectWrapper;
      const dropdown = this.$refs.dropdown;
      if (
        !(selectWrapper === event.target || selectWrapper.contains(event.target)) &&
        !(dropdown && dropdown.contains(event.target))
      ) {
        this.dropdownVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ReferralSelectBox {
  &.is-invalid {
    small.form-text.text-danger {
      font-size: 0.8rem;
    }

    > label {
      color: var(--color-danger) !important;
    }
  }

  position: relative;
  height: 36px;
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 3.1rem;
}

.chosen-value {
  position: relative;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  padding: 0.35rem 0.5rem;
  /* border-radius: 3px; */
  height: 36px;
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: 0.25rem;
  z-index: 1;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ReferralSelectBox .chosen-value.placeholder {
  color: var(--text-muted);
}

.dropdown {
  border-radius: 3px;
  border: 1px solid #d2d2d2;
  background: var(--light-background-light, #fff);
  width: 100%;
  max-height: 295.64px;
  padding: 0.5rem;
  z-index: 100;
}

.dropdown > div {
  color: #4f5e77;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 223.523%;
  cursor: pointer;
  padding: 0.3rem;
}

.dropdown > div:hover {
  background: #f5f5f5;
}

.ReferralSelectBox .form-select-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--text-light);
  font-size: 0.8rem;
}

.otherInput {
  width: 100%;
  height: 32px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #d2d2d2;
  background: var(--light-background-light, #fff);
}
.otherInput:focus {
  outline: none;
}

@media (max-width: 767px) {
  .dropdown {
    width: 100%;
    max-height: 300px;
  }
}
</style>
