<template>
  <div class="trial__banner">
    <div class="trial__banner__background">
      <div class="trial__banner_text">
        <h1>{{ $t('Your free trial includes:') }}</h1>
        <ul>
          <li>
            {{ $t('All features enabled') }}
          </li>
          <li>
            {{ $t('Mobile and Desktop Apps - Sync, Drive, Add-ons for Office & Outlook') }}
          </li>
          <li>
            {{ $t('Admin Portal for managing your organization') }}
          </li>
          <li>
            {{ $t('Full support during trial') }}
          </li>
        </ul>
        <p>{{ $t('Note: During deployment, you will have a choice of regions, but the trial runs in US East.') }}</p>
      </div>
      <div class="trial__banner__effect"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
/* */
</style>
