<template>
  <div
    class="multi-column-select form-group"
    :class="{ [`is-invalid`]: errors[id] }"
  >
    <template v-if="label">
      <label :for="id" class="">{{ label }}</label>
    </template>

    <v-popover
      placement="bottom-start"
      class="multi-column-select__trigger-wrapper"
      popover-class="multi-column-select__popover"
    >
      <div
        class="chosen-value input-group"
        :class="{ placeholder: !chosenValue }"
      >
        {{ chosenValue || placeholder }}
        <Icon class="form-select-icon" family="fas" name="chevron-down" />
      </div>

      <template slot="popover">
        <div class="multi-column-select__dropdown">
          <div class="column">
            <div
              v-for="(option, index) in column1"
              :key="index"
              v-close-popover
              @click="selectValue(option.value)"
              v-text="option.text"
            ></div>
          </div>
          <div class="column">
            <div
              v-for="(option, index) in column2"
              :key="index"
              v-close-popover
              @click="selectValue(option.value)"
              v-text="option.text"
            ></div>
          </div>
          <div class="column">
            <div
              v-for="(option, index) in column3"
              :key="index"
              v-close-popover
              @click="selectValue(option.value)"
              v-text="option.text"
            ></div>
            <!-- Commented out 'Other' input, uncomment if needed in the future -->
            <!--
        <input
          v-if="chosenValue === 'other'"
          v-model="otherValue"
          class="other-input"
          placeholder="Your Industry"
          @blur="selectOther"
          @keydown.enter="selectOther"
        /> -->
          </div>
        </div>
      </template>
    </v-popover>

    <small
      v-if="errors[id]"
      class="form-text text-danger"
      v-html="errors[id].message"
    ></small>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
export default {
  name: 'MultiColumnSelect',
  components: {
    Icon,
  },
  props: {
    id: String,
    label: String,
    value: String,
    placeholder: String,
    column1: Array,
    column2: Array,
    column3: Array,
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dropdownVisible: false,
      chosenValue: '',
      otherValue: '',
      showOther: false,
    };
  },
  watch: {
    value(value) {
      this.chosenValue = this.getOptionText(value);
    },
  },
  methods: {
    selectValue(value) {
      // Updated method. 'Other' input functionality commented out, uncomment it if needed.
      //if (value === 'other') {
      //  this.chosenValue = value; changes
      //} else {
      this.chosenValue = this.getOptionText(value);
      this.dropdownVisible = false;
      this.$emit('input', value);
      // }
    },
    // Commented out method for 'Other' input, uncomment it if needed in the future.
    /*
    selectOther() {
      this.dropdownVisible = false;
      this.$emit('input', this.otherValue);
      this.chosenValue = this.otherValue;
    },
    */
    getOptionText(value) {
      const columns = [this.column1, this.column2, this.column3];
      for (const column of columns) {
        for (const option of column) {
          if (option.value === value) {
            return option.text;
          }
        }
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
.multi-column-select__popover {
  .tooltip-arrow {
    display: none;
  }

  .multi-column-select__dropdown {
    display: grid;
    grid-template-columns: 1fr;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 3px;
    width: 655.86px;
    max-height: 410.1px;
    overflow-y: auto;
    padding: 0.5rem;

    @media (min-width: 992px) {
      gap: 0.5rem;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 767px) {
      width: 100%;
      max-height: 300px;
    }

    @media (min-width: 641px) {
      /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */

      width: 100%;
      max-height: 300px;
    }
    @media (min-width: 961px) {
      /* tablet, landscape iPad, lo-res laptops ands desktops */

      width: 655.86px;
      max-height: 410.1px;
    }

    .column {
      display: block;

      > div {
        text-align: left;
        cursor: pointer;
        padding: 0.25rem 0.5rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #4f5e77;

        &:hover {
          background: #f5f5f5;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.multi-column-select {
  &__trigger-wrapper {
    ::v-deep .trigger {
      width: 100%;
    }
  }

  .chosen-value {
    background-color: white;
    border: 1px solid #d2d2d2;
    padding: 0.25rem 0.5rem;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    align-items: center;
    height: 38px;
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .form-select-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--text-light);
    font-size: 0.8rem;
  }

  input {
    margin-top: 0.5rem;
    grid-column: 3 / -1;
    width: 100%;
    border: 1px solid #d2d2d2;
    border-radius: 3px;
    font-size: 0.9rem;
  }

  .chosen-value.placeholder {
    color: var(--text-muted);
  }
}

.other-input {
  width: 90%;
  height: 32px;
  margin-top: 0.5rem;
  padding: 0.5em;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
}
.otherInput:focus {
  outline: none;
}
</style>
