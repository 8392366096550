<template>
  <div
    :id="id"
    ref="multiselect"
    class="multi-select form-group"
    :class="{ [`is-invalid`]: errors[id] }"
  >
    <label v-if="label">{{ label }}</label>

    <div
      :class="{
        'chosen-value': true,
        placeholder: !selectedOptions.length,
      }"
      @click="toggleDropdown"
    >
      <span v-if="selectedOptions.length === 1">{{ selectedOptions[0] }}</span>

      <span v-else-if="selectedOptions.length">
      {{ selectedOptions.length }} Options Selected
    </span>

      <span v-if="!selectedOptions.length">{{ placeholder }}</span>

      <!-- <span v-if="otherValue">{{
        selectedOptions.length ? `, ${otherValue}` : otherValue
      }}</span>

      <span v-if="!(selectedOptions.length || otherValue)">{{
        placeholder
      }}</span> -->

      <Icon class="form-select-icon" family="fas" name="chevron-down" />
    </div>

    <div v-show="dropdownVisible" class="dropdown">
      <div class="notice">You can select multiple options</div>
      <div v-for="(option, index) in options" :key="index" class="option">
        <label :for="option.value" class="option-container option-label">
          <input
            :id="option.value"
            v-model="selected[option.value]"
            type="checkbox"
            class="option-checkbox"
          />
          <div class="option-label">{{ option.text }}</div>

          <span v-if="selected[option.value]" class="checkmark">
            <Icon family="fal" name="check" />
          </span>
        </label>
        <!-- Commenting out the "other" input section as per requirements -->
        <!--
        <div v-if="option.value === 'other' && selected.other">
          <input
            v-model="otherValue"
            class="otherInput"
            placeholder="Your Objective"
            @blur="selectOther"
          />
        </div>
        -->
      </div>
    </div>

    <small
      v-if="errors[id]"
      class="form-text text-danger"
      v-html="errors[id].message"
    ></small>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
export default {
  name: 'MultiSelect',
  components: {
    Icon,
  },
  props: {
    id: String,
    label: String,
    placeholder: String,
    options: Array,
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dropdownVisible: false,
      selected: [],
      otherValue: '',
    };
  },
  computed: {
    selectedOptions() {
      return Object.keys(this.selected).filter(
        //(key) => this.selected[key] && key !== 'other'
        (key) => this.selected[key]
      );
    },
  },
  watch: {
    selected: {
      handler(val) {
        const selectedOptions = Object.keys(val).filter(
          (key) => val[key] // && key !== 'other'
        );
        this.$emit('input', selectedOptions.join(', '));
        //this.otherValue && selectedOptions.push(this.otherValue);
        //this.$emit('input', selectedOptions.join(', ')); // Joining multiple objectives with ', '
      },
      deep: true,
    },
    otherValue() {
      // Always emit 'other', not the user's input
      this.selected.other
        ? this.$emit('input', [...this.selectedOptions, 'Other'].join(', '))
        : this.$emit('input', this.selectedOptions.join(', '));

      /*
      //This part was responsible for including the user's input when emitting the selected options
      this.selected.other
        ? this.$emit('input', [...this.selectedOptions, val].join(', '))
        : this.$emit('input', this.selectedOptions.join(', '));
      */
    },
  },
  mounted() {
    // Add event listener on document
    document.addEventListener('click', this.closeDropdownOnClickOutside);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    document.removeEventListener('click', this.closeDropdownOnClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
   /* selectOther() {
      // Always emit 'other', not the user's input
      if (this.selectedOptions.length) {
        // If the user has selected other options along with 'other'
        this.$emit('input', [...this.selectedOptions, 'other'].join(', '));
      } else {
        // If only 'other' is selected
        this.$emit('input', 'other');
      }
      this.dropdownVisible = false;

      /*
      //This part was responsible for including the user's input when emitting the selected options
      if (this.selectedOptions.length && this.otherValue) {
        // If the user has selected other options along with 'other', we include them all
        this.$emit(
          'input',
          [...this.selectedOptions, this.otherValue].join(', ')
        );
      } else {
        // If only 'other' is selected, we emit just the user-inputted value
        this.$emit('input', this.otherValue);
      }
      this.dropdownVisible = false;
      */
   /* }, */
    closeDropdownOnClickOutside(event) {
      // Check if click was outside the multiselect
      if (!this.$el.contains(event.target)) {
        this.dropdownVisible = false; // Close dropdown if click was outside
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-select {
  &.is-invalid {
    small.form-text.text-danger {
      font-size: 0.8rem;
    }

    > label {
      color: var(--color-danger) !important;
    }
  }

  position: relative;
  /* left: 9.03%;
  right: 74.86%;
  top: 41.71%;
  bottom: 37.19%; */
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.chosen-value {
  position: relative;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  padding: 0.35rem 0.5rem;
  /* border-radius: 3px; */
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0.25rem;
  z-index: 1;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.multi-select .chosen-value.placeholder {
  color: var(--text-muted);
}
.form-select-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--text-light);
  font-size: 0.8rem;
}

@media screen and (max-width: 600px) {
  .form-select-icon {
    right: 0.5rem;
    font-size: 0.7rem;
  }
}

.notice {
  background: #f5f5f5;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: #4f5e77;
  text-align: left;
  padding: 1em;
  margin-bottom: 1em;
}

.otherInput {
  width: 90%;
  height: 32px;
  margin-left: 25px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #d2d2d2;
  background: var(--light-background-light, #fff);
}
.otherInput:focus {
  outline: none;
}

.option-label {
  display: inline-block;
  padding-left: 0.4em;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
}

.option-checkbox {
  position: relative;
  top: 3px;
}

.checkmark {
  position: absolute;
  top: 5px; //updated
  left: 8px;
  height: 15.468px;
  width: 15.468px;
  /*background-color: #eee;*/
}

.checkmark .fal {
  font-size: 0.7rem;
  font-weight: 500;
  color: #0067ad;
  transition: 0.1s linear;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.dropdown {
  position: absolute;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  width: 287.95px;
  max-height: 397.64px;
  padding: 0.5rem;
  z-index: 100;
}
.dropdown .option {
  position: relative;
  box-sizing: border-box;
  /*padding: 0.3rem;*/
  padding-top: 0.3rem;
}
.dropdown .option input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  width: 15.468px;
  height: 15.468px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
}

.dropdown .option input:checked ~ .checkmark:after {
  display: block;
}

.dropdown .option .checkmark:after {
  left: 6px;
  top: 12px;
  width: 5px;
  height: 10px;
  /*border: solid white;*/
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dropdown .option:hover {
  background-color: #f5f5f5;
}
@media (max-width: 767px) {
  .multi-select {
    width: 100%;
    position: relative;
    margin-bottom: 4.5rem;
  }

  .dropdown {
    width: 100%;
    max-height: 400px;
  }
  .otherInput {
    width: 100%;
    height: 32px;
    margin-left: 0;
  }
}
</style>
