<template>
  <div class="auth__area trial__page__welcome">
    <h1>{{ $t('Welcome to FileCloud!') }}</h1>
    <h4
      v-html="
        $t(
          'Please choose the FileCloud solution that best suits you for a free trial (15-day trial online or 30-day trial server).'
        )
      "
    />

    <form>
      <div class="row trial__page__welcome_cards_wrapper">
        <div class="col-12 col-md-6 mb-2">
          <div class="card p-3" @click="onSelect('online')">
            <div class="row no-gutters align-items-center">
              <Clouds class="col-4" />
              <div class="col-8 d-flex flex-column">
                <h4>{{$t('Online')}}</h4>
                <span>{{$t('Run FileCloud on our secure infrastructure')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6  mb-2">
          <div class="card p-3" @click="onSelect('server')">
            <div class="row no-gutters align-items-center">
              <Warehouse class="col-4" />
              <div class="col-8 d-flex flex-column">
                <h4>{{$t('Server')}}</h4>
                <span>{{$t('Run FileCloud on your own infrastructure')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          row
          no-gutters
          flex-row-reverse
          d-sm-flex
          justify-content-between
          align-items-center
          mx-5
          mb-3
          mx-lg-0
        "
      >
        <div class="d-flex align-items-center">
          <button class="btn btn-link px-0 pull-rightfast d-none" type="button">
            {{ $t('Back') }}
          </button>
        </div>

        <div class="col mr-auto">
          <SupportBlock minified />
        </div>
      </div>
    </form>
    <div class="divider">
      {{ $t('or') }}
    </div>

    <div
      class="
        row
        no-gutters
        flex-row-reverse
        d-sm-flex
        justify-content-center
        align-items-center
        mx-5
        mb-3
        mx-lg-0
      "
    >
      <div class="col-sm-12 col-lg-7">
        <router-link
          v-if="!noHistory"
          class="btn btn-outline-primary mb-3 mb-lg-0 col-12"
          to="/login"
        >
          {{ $t('Already have an account? Log in') }}
        </router-link>
        <a
          v-else
          class="btn btn-outline-primary mb-3 mb-lg-0 col-12"
          target="_blank"
          href="https://portal.getfilecloud.com/ui/user/index.html#/login"
        >
          {{ $t('Already have an account? Log in') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Clouds from '@/assets/clouds.svg';
import Warehouse from '@/assets/warehouse.svg';
import SupportBlock from '@/components/Partials/SupportBlock';

export default {
  name: 'Welcome',
  components: {
    Clouds,
    Warehouse,
    SupportBlock
  },
  props: {
    noHistory: Boolean
  },
  mounted() {
    this.$store.dispatch('core/sendAnalyticsEvent', {
      category: 'cerebro-trial',
      action: 'welcome',
      label: '',
    })
  },
  methods: {
    onSelect(type) {
      this.$emit('changeStep', { name: type });
    },
  }
};
</script>
