<template>
  <div class="steps-wrapper">
    <div class="steps-wrapper__steps">
      <span
        v-for="(stepTitle, index) in steps"
        :key="index"
        class="step"
        :class="{ active: stepTitle === activeStepTitle }"
      >
        <b>{{ index + 1 }}</b> {{ stepTitle }}
      </span>
    </div>

    <div v-if="showSeparationLine" class="separation-line"></div>

    <slot :next-step="nextStep" :previous-step="previousStep"></slot>
  </div>
</template>

<script>
import Validator from 'fastest-validator';

export default {
  name: 'StepsWrapper',
  expose: [
    'nextStep',
    'previousStep',
    'mapErrors',
    'validator',
    'currentStepId',
  ],
  provide() {
    return {
      activeStepTitle: () => this.activeStepTitle,
    };
  },
  props: {
    showSeparationLine: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentStepId: 1,
      validator: new Validator({
        messages: {
          stringEmpty: 'This field must not be empty.',
        },
      }),
    };
  },
  computed: {
    steps() {
      if (!this.$slots.default.length) return [];
      return this.$slots.default.map(
        (step) => step.componentOptions.propsData.title
      );
    },
    activeStepTitle() {
      return this.steps[this.currentStepId - 1];
    },
  },
  methods: {
    nextStep() {
      if (this.currentStepId < this.steps.length) {
        this.currentStepId++;
      }
    },
    previousStep() {
      if (this.currentStepId > 1) {
        this.currentStepId--;
      }
    },
    mapErrors(errors) {
      let response = {};
      errors.forEach((error) => {
        const { actual, expected, field, message } = error;
        response[error.field] = {
          message,
          meta: {
            actual,
            expected,
            field,
          },
        };
      });
      return response;
    },
  },
};
</script>

<style lang="scss" scoped>
.steps-wrapper {
  display: flex;
  flex-direction: column;

  &__steps {
    display: flex;
    gap: 50px;
    margin-bottom: 25px;

    .step {
      color: #d2d2d2;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;

      &.active {
        color: #0e69ab;

        b {
          background-color: #0e69ab;
          color: #ffffff;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          border: 0;
        }
      }

      b {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #fff;
        color: #d2d2d2;
        line-height: 20px;
        text-align: center;
        margin-right: 5px;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        border: 1px solid #d2d2d2;
      }
    }
  }
}

.separation-line {
  border-top: 1px solid #d2d2d2;
  background-color: #d2d2d2;
  height: 1px;
  margin-bottom: 25px;
}
</style>
